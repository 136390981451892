import { createContext, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

// types
import type { IMeta } from './meta.types';

// utils
import { decodingContent } from 'utils/decodingContent';
import { SchemaOrganization } from 'utils/schemaOrganization';
import {
  socialMediaItemsSelector,
  globalsSearchUrlSelector,
  googleValidationSelector,
  baseURLSelector,
  pinterestValidationSelector,
  baiduValidationSelector,
  faviconSelector,
} from 'utils/selectors/globalsSelectors';
import { configureDescription, configureTitle } from 'utils/metaTagConfigurations';
import { selectSearchQuery } from 'utils/selectors/searchSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useNord } from 'utils/hooks/use-nord';
import { useGroup } from 'utils/hooks/use-group';

const MetaDataContext = createContext({ lang: '' });

export function IsoLangProvider({ children, lang }) {
  useEffect(() => {
    document.querySelector('html')?.setAttribute('lang', lang);
  }, [lang]);

  const value = useMemo(() => ({ lang }), [lang]);

  return <MetaDataContext.Provider value={value}>{children}</MetaDataContext.Provider>;
}

export function useIsoLang() {
  const { lang } = useContext(MetaDataContext);

  return lang.split('-').join('_');
}

export function useIsoCountry() {
  return useIsoLang().split('_').pop() ?? '';
}

function getSiteName(
  hasTitleName: boolean,
  titleConfigured: string | undefined,
  titleClaim: string,
) {
  // e.g. PDP has another siteName than default pages
  return hasTitleName ? titleConfigured : titleClaim;
}

function getMetaImage(image: any, baseUrl: string | null) {
  let metaImage = image || '/images/geberit-logo.jpg';

  if (!metaImage.startsWith('https://')) {
    metaImage = `${baseUrl}${metaImage}`;
  }

  return metaImage;
}

function MetaData({
  lang,
  title,
  canonical,
  image: defaultImage,
  alternate,
  description,
  keywords,
  robots,
  jobTitle,
  name,
  hasTitleName = false,
  type = 'website',
  imageObjectMobile,
  noTitleClaim = false,
}: Readonly<IMeta>) {
  const isGroup = useGroup();
  const isNord = useNord();
  const translate = useTranslationFunction();

  const baseUrl = useSelector(baseURLSelector);
  const titleClaim = translate('web20_title_claim');
  const descriptionClaim = translate('web20_description_claim');
  const country = translate('web20_logo_claim');
  const customerServicePhone = translate('web20_customer_service_telephone');
  const salesPhone = translate('web20_sales_telephone');
  const billingSupportPhone = translate('web20_billing_support_telephone');
  const technicalSupportPhone = translate('web20_technical_support_telephone');
  const twitterSite = translate('group_meta_twitter_site');
  const twitterCreator = translate('group_meta_twitter_creator');
  const twitterSiteId = translate('group_meta_twitter_siteId');
  const groupDescriptionDefault = translate('group_meta_description');
  const socialMediaLinks = useSelector(socialMediaItemsSelector);
  const searchQuery = useSelector(selectSearchQuery);
  const searchUrl = useSelector(globalsSearchUrlSelector);
  const googleValidation = useSelector(googleValidationSelector);
  const pinterestValidation = useSelector(pinterestValidationSelector);
  const baiduValidation = useSelector(baiduValidationSelector);
  const icons = useSelector(faviconSelector);

  const isFaviconFallback = Boolean(isNord && defaultImage?.endsWith('.svg') && icons?.icon270Ref);
  const image = isFaviconFallback ? icons?.icon270Ref : defaultImage;
  const schema = SchemaOrganization({
    country,
    customerServicePhone,
    technicalSupportPhone,
    billingSupportPhone,
    salesPhone,
    socialLinks: socialMediaLinks,
    searchUrl,
    searchQuery,
    image: decodingContent(image),
  });

  const titleConfigured = configureTitle({
    jobTitle,
    name,
    title,
    titleClaim: noTitleClaim ? '' : titleClaim,
  });
  const descriptionConfigured = configureDescription(name, description, descriptionClaim);
  const metaImage = getMetaImage(image, baseUrl);
  const siteName = getSiteName(hasTitleName, titleConfigured, titleClaim);
  const projectDescription = description ?? groupDescriptionDefault;
  const projectImage = imageObjectMobile?.url ?? metaImage;

  let ogSiteName = twitterSite;
  let ogTitle = title;
  let ogDescription = projectDescription;
  let ogImage = projectImage;

  if (!isGroup) {
    ogSiteName = decodingContent(siteName);
    ogTitle = decodingContent(titleConfigured);
    ogDescription = decodingContent(descriptionConfigured);
    ogImage = metaImage;
  }

  return (
    <Head>
      <script key="application-ld-json" type="application/ld+json">
        {JSON.stringify(schema)}
      </script>

      {googleValidation && (
        <meta
          key="google-site-verification"
          name="google-site-verification"
          content={googleValidation}
        />
      )}
      {pinterestValidation && (
        <meta key="p-domain-verify" name="p:domain_verify" content={pinterestValidation} />
      )}
      {baiduValidation && (
        <meta
          key="baidu-site-verification"
          name="baidu-site-verification"
          content={baiduValidation}
        />
      )}
      {keywords && <meta key="keywords" name="keywords" content={decodingContent(keywords)} />}
      {description && (
        <meta key="description" name="description" content={decodingContent(description)} />
      )}
      {robots && <meta key="robots" name="robots" content={robots} />}
      {image && <meta key="image" name="image" content={decodingContent(image)} />}

      {/* schema org */}
      {titleConfigured && (
        <meta key="item-prop-name" itemProp="name" content={decodingContent(titleConfigured)} />
      )}
      {descriptionConfigured && (
        <meta
          key="item-prop-description"
          itemProp="description"
          content={decodingContent(descriptionConfigured)}
        />
      )}
      {metaImage && <meta key="item-prop-image" itemProp="image" content={metaImage} />}
      {titleConfigured && <title>{decodingContent(titleConfigured)}</title>}
      {canonical && <link key="canonical" rel="canonical" href={`${baseUrl}${canonical}`} />}
      {alternate?.map((alternative) => (
        <link
          key={`alternate-${alternative.lang}`}
          rel="alternate"
          href={`${baseUrl}${alternative.url}`}
          hrefLang={alternative.lang.toLocaleLowerCase().replace('_', '-')}
        />
      ))}

      {/* PLATFORM && PROJECT  */}
      {(!isNord || icons?.icon270Ref) && (
        <link
          key="apple-touch-icon"
          rel="apple-touch-icon"
          sizes={icons?.icon270Ref ? '270x270' : '180x180'}
          href={icons?.icon270Ref || '/images/fav/apple-touch-icon.png'}
        />
      )}

      {!isNord ||
        (icons?.icon128Ref && (
          <link
            key="shortcut-icon"
            rel="shortcut icon"
            type="image/x-icon"
            sizes="128x128"
            href={icons?.icon128Ref || '/images/fav/favicon-128x128.png'}
          />
        ))}
      {(!isNord || icons?.icon32Ref) && (
        <link
          key="icon-32"
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={icons?.icon32Ref || '/images/fav/favicon-32x32.png'}
        />
      )}
      {!isNord ||
        (icons?.icon16Ref && (
          <link
            key="icon-16"
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={icons?.icon16Ref || '/images/fav/favicon-16x16.png'}
          />
        ))}
      <link
        key="manifest"
        rel="manifiest"
        href="/images/fav/site.webmanifest"
        crossOrigin="use-credentials"
      />
      <link
        key="mask-icon"
        rel="mask-icon"
        href="/images/fav/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      {/* semantic web fields */}
      <meta key="og-type" property="og:type" content={type} />
      {ogSiteName && <meta key="og-site-name" property="og:site_name" content={ogSiteName} />}
      {canonical && <meta key="og-url" property="og:url" content={`${baseUrl}${canonical}`} />}
      {lang && <meta key="og-locale" property="og:locale" content={lang} />}
      {ogTitle && <meta key="og-title" property="og:title" content={ogTitle} />}
      {ogDescription && (
        <meta key="og-description" property="og:description" content={ogDescription} />
      )}

      {/* Image for share */}
      {ogImage && <meta key="og-image" property="og:image" content={ogImage} />}
      {isGroup && projectImage && (
        <meta key="og-image-url" property="og:image:url" content={projectImage} />
      )}
      {isGroup && projectImage && (
        <meta key="og-image-secure-url" property="og:image:secure_url" content={projectImage} />
      )}
      {isFaviconFallback && <meta key="og-image-width" property="og:image:width" content="270" />}

      {isFaviconFallback && <meta key="og-image-height" property="og:image:height" content="270" />}

      {/* twitter */}
      {isGroup && <meta key="twitter-card" name="twitter:card" content="summary_large_image" />}
      {isGroup && twitterSiteId && (
        <meta key="twitter-site" name="twitter:site" content={twitterSiteId} />
      )}
      {isGroup && twitterCreator && (
        <meta key="twitter-creator" name="twitter:creator" content={twitterCreator} />
      )}
      {isGroup && twitterCreator && (
        <meta key="twitter-creator-id" name="twitter:creator:id" content={twitterCreator} />
      )}
      {isGroup && <meta key="twitter-site-id" name="twitter:site:id" content={`${baseUrl}`} />}
      {isGroup && canonical && (
        <meta key="twitter-url" name="twitter:url" content={`${baseUrl}${canonical}`} />
      )}
      {isGroup && title && <meta key="twitter-title" name="twitter:title" content={title} />}
      {isGroup && projectDescription && (
        <meta key="twitter-description" name="twitter:description" content={projectDescription} />
      )}
      {isGroup && projectImage && (
        <meta key="twitter-image" name="twitter:image" content={projectImage} />
      )}
    </Head>
  );
}

export default MetaData;
